<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row>
      <b-col cols="12">
        <h4 class="text-primary">
          <feather-icon icon="PlusSquareIcon" size="20"/>
          Add new product
        </h4>

        <b-row>
          <b-media class="my-2 col-12">
            <validation-observer
                ref="refFormObserver"
            >
              <b-card class="">
                <b-row>
                  <h4 class="text-primary col-12">Information</h4>
                  <b-col cols="12" md="6">
                    <template>
                      <p class="mb-1">Product picture</p>
                      <b-avatar
                          ref="previewEl"
                          :src="base64ImageSrc"
                          class="cursor-pointer mb-1"
                          rounded
                          size="150px"
                          @click.native="$refs.refInputEl.click()"
                      >
                        <feather-icon v-if="base64ImageSrc === null" icon="PlusSquareIcon" size="100"/>
                      </b-avatar>
                    </template>
                    <b-button
                        class="mt-2 mb-sm-0 mr-2 col-12 col-md-5 d-none"
                        variant="warning"
                        @click="$refs.refInputEl.click()"
                    >
                      <input
                          ref="refInputEl"
                          accept=".jpg,.jpeg,.png,.svg"
                          class="d-none"
                          type="file"
                          @input="setCover($event)"
                      >
                    </b-button>
                  </b-col>

                  <b-col cols="12" md="6">
                    <language-picker
                        @getLanguage="setLanguageId"
                    ></language-picker>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                        class="mb-2"
                        label="Name"
                        label-for="name"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="name"
                            v-model="product.name"
                            :state="errors.length > 0 ? false:null"
                        ></b-form-input>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                        class="mb-2"
                        label="SubTitle"
                        label-for="subTitle"
                    >
                      <b-form-input
                          id="subTitle"
                          v-model="product.subTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group label="Choose Product Category" label-for="blog-category">
                      <v-select
                          v-if="categories"
                          id="blog-category"
                          v-model="product.productCategoryId"
                          :options="categories"
                          :reduce="(category) => category.id"
                          label="name"
                          placeholder="Choose your desired category"
                      >
                        <template #no-options>There is no category available</template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                        class="mb-2"
                        label="Price"
                        label-for="priceValue"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="priceValue"
                            v-model="product.priceValue"
                            :state="errors.length > 0 ? false:null"
                        ></b-form-input>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                        class="mb-2"
                        label="Min Order"
                        label-for="minOrder"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="minOrder"
                            v-model="product.minOrder"
                            :state="errors.length > 0 ? false:null"
                        ></b-form-input>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group label="Choose Product Shipments" label-for="shipments">
                      <v-select
                          v-if="shipments"
                          id="shipments"
                          v-model="product.shipments"
                          :multiple="true"
                          :options="shipments"
                          :reduce="(shipment) => shipment.id"
                          label="name"
                          placeholder="Choose your desired shipments"
                      >
                        <template #no-options>There is no shipments available</template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                        class="mb-2"
                        label="Short Description"
                        label-for="shortDescription"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-textarea
                            id="shortDescription"
                            v-model="product.shortDescription"
                            :state="errors.length > 0 ? false:null"
                        ></b-form-textarea>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="12">
                    <b-form-group
                        class="mb-2"
                        label="Full Description"
                    >
                      <ckEditorMain ref="editorContent" :defaultContent="product.fullDescription"
                                    @getEditorContent="setEditorContent"></ckEditorMain>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>

              <b-card>
                <b-row>
                  <h4 class="text-primary col-12 mb-2">Properties</h4>

                  <b-col v-for="(property,idx) in product.properties" :key="idx" cols="12">
                    <ProductCategoryProperty
                        :product="product"
                        :property="property"
                        :idx="idx"
                        :CPG="CPG"
                    />
                  </b-col>
                  <b-col cols="12">
                    <b-button
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        class="col-12 col-md-2"
                        variant="success"
                        @click="addProperty"
                    >
                      + Add property
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>

              <b-card>
                <b-row>
                  <h4 class="text-primary col-12 mb-2">Trailer</h4>
                  <b-col cols="12" md="3">
                    <video
                        style="max-height: 30rem"
                        v-if="base64Trailer"
                        controls
                        crossorigin
                        playsinline
                        :src="base64Trailer"></video>
                    <b-button
                        class="mt-2 col-6"
                        variant="warning"
                        @click="$refs.refInputEl2.click()"
                    >
                      <input
                          accept=".webm,.mp4,.ogg"
                          ref="refInputEl2"
                          type="file"
                          class="d-none"
                          @change="setVideo"
                      >
                      <span class="">Add video</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>

              <b-card>
                <b-row>
                  <b-col cols="12">
                    <b-button
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        class="col-12 col-md-2"
                        variant="primary"
                        @click="addProduct"
                    >
                      Save
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </validation-observer>
          </b-media>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Helper from "@/libs/Helper";
import {required} from '@validations';
import {CategoryPropertyGroupGetBy, ProductCategoriesGetSubCategories,} from "@/libs/Api/ProductCategory";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";
import {GetAllLangs} from "@/libs/Api/Language";
import {ShipmentGetAll} from "@/libs/Api/Shipment";
import ckEditorMain from "@/views/components/ckEditorMain.vue";
import ProductCategoryProperty from "@/views/apps/product/ProductCategoryProperty.vue";
import {ProductCreate} from "@/libs/Api/Product";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  title: 'Create product',
  data() {
    return {
      required,
      languageList: [],
      overlay: false,
      categories: [],
      shipments: [],
      CPG: [],
      product: {
        languageId: 1,
        name: null,
        productCategoryId: null,
        mainId: '',
        priceValue: null,
        // imageFile: null,
        // videoFile: null,
        seoTitle: '',
        seoDescription: '',
        seoUrl: '',
        minOrder: '',
        subTitle: '',
        shipments: [],
        shortDescription: '',
        fullDescription: '',
        properties: [],
      },
      base64ImageSrc: null,
      modalCoverPic: null,
      base64Trailer: null,
    }
  },
  async created() {
    if (this.$route.query.mainId && this.$route.query.languageId){
      this.product.mainId = this.$route.query.mainId;
      this.product.languageId = this.$route.query.languageId;
    }
    await this.getSubCategories();
    await this.getShipments();
  },
  watch: {
    'product.languageId': function (nv, ov) {
      this.getSubCategories();
      this.getShipments();
    },
    'product.productCategoryId': function (nv, ov) {
      this.getCategoryPropertyGroup();
    },
  },
  methods: {
    async addProduct() {
      let _this = this;
      Helper.validateForm(_this, 'refFormObserver', async () => {
        let formData = Helper.generateFormDataFromObject(_this.product);
        formData.append('imageFile', _this.$refs.refInputEl.files[0]);
        formData.append('videoFile', _this.$refs.refInputEl2.files[0]);
        console.log(formData);
        _this.overlay = true;
        let productCreate = new ProductCreate(_this);
        productCreate.setParams(formData);
        await productCreate.sendFormData((content) => {
          _this.overlay = false;
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Operation successful`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          _this.$router.push('/apps/product/list');
        }, (error) => {
          _this.overlay = false;
          console.log(error)
        });
      })
    },
    async getCategoryPropertyGroup() {
      let _this = this;
      _this.overlay = true;

      let data = {
        ProductCategoryId:_this.product.productCategoryId,
        languageId: _this.product.languageId
      }

      let categoryPropertyGroupGetBy = new CategoryPropertyGroupGetBy(_this);
      categoryPropertyGroupGetBy.setParams(data);
      await categoryPropertyGroupGetBy.fetch(function (content) {
        _this.overlay = false;
        _this.CPG = content.data.data;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async getSubCategories() {
      let _this = this;
      _this.overlay = true;

      let data = {
        languageId: _this.product.languageId
      }

      let productCategoriesGetSubCategories = new ProductCategoriesGetSubCategories(_this);
      productCategoriesGetSubCategories.setParams(data);
      await productCategoriesGetSubCategories.fetch(function (content) {
        _this.overlay = false;
        _this.categories = content.data.data;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async getShipments() {
      let _this = this;
      _this.overlay = true;

      let data = {
        languageId: _this.product.languageId
      }

      let shipmentGetAll = new ShipmentGetAll(_this);
      shipmentGetAll.setParams(data);
      await shipmentGetAll.fetch(function (content) {
        _this.overlay = false;
        _this.shipments = content.data.data;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    addProperty(){
      this.product.properties.push({
        categoryPropertyId: 0,
        categoryPropertyGroupId: 0,
        value: "",
        priority: '',
        showInContent: false
      });
    },
    generateObjectForAddEditRemoveButton(data) {
      return {
        id: data.id,
        languageId: data.languageId,
        otherLanguages: data.otherLanguages || [],
        mainId: data.mainId,
      };
    },
    async getAllLanguages() {
      try {
        let requestParams = {
          count: 100,
          pageNumber: 1,
        };
        if (this.searchCommand) {
          requestParams["searchCommand"] = this.searchCommand;
        }
        this.isLoading = true;
        let _this = this;
        let getAllLangs = new GetAllLangs(_this);

        getAllLangs.setRequestParam(requestParams);
        await getAllLangs.fetch((response) => {
          if (response.isSuccess) {
            _this.languageList = response.data.languages;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setEditorContent(content) {
      this.product.fullDescription = content;
    },
    clickOnInput(id) {
      let input = document.getElementById(id);
      input.click();
    },
    setLanguageId(language) {
      this.product.languageId = language.languageId;
    },
    setCover(e) {
      let file = e.target.files[0];
      this.base64ImageSrc = URL.createObjectURL(file);
    },
    setVideo(event) {
      let file = event.target.files.item(0);
      this.base64Trailer = URL.createObjectURL(file);
    },
  },
  components: {
    ckEditorMain,
    AddEditDeleteButton,
    LanguagePicker,
    BModal,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BTable,
    BPagination,
    ValidationObserver, ValidationProvider,
    ProductCategoryProperty,
  },
}
</script>

<style>
.modal-header > .close {
  display: none !important;
}
</style>