var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.overlay,"rounded":"sm"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-primary"},[_c('feather-icon',{attrs:{"icon":"PlusSquareIcon","size":"20"}}),_vm._v(" Add new product ")],1),_c('b-row',[_c('b-media',{staticClass:"my-2 col-12"},[_c('validation-observer',{ref:"refFormObserver"},[_c('b-card',{},[_c('b-row',[_c('h4',{staticClass:"text-primary col-12"},[_vm._v("Information")]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[[_c('p',{staticClass:"mb-1"},[_vm._v("Product picture")]),_c('b-avatar',{ref:"previewEl",staticClass:"cursor-pointer mb-1",attrs:{"src":_vm.base64ImageSrc,"rounded":"","size":"150px"},nativeOn:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[(_vm.base64ImageSrc === null)?_c('feather-icon',{attrs:{"icon":"PlusSquareIcon","size":"100"}}):_vm._e()],1)],_c('b-button',{staticClass:"mt-2 mb-sm-0 mr-2 col-12 col-md-5 d-none",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"accept":".jpg,.jpeg,.png,.svg","type":"file"},on:{"input":function($event){return _vm.setCover($event)}}})])],2),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('language-picker',{on:{"getLanguage":_vm.setLanguageId}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"SubTitle","label-for":"subTitle"}},[_c('b-form-input',{attrs:{"id":"subTitle"},model:{value:(_vm.product.subTitle),callback:function ($$v) {_vm.$set(_vm.product, "subTitle", $$v)},expression:"product.subTitle"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Choose Product Category","label-for":"blog-category"}},[(_vm.categories)?_c('v-select',{attrs:{"id":"blog-category","options":_vm.categories,"reduce":function (category) { return category.id; },"label":"name","placeholder":"Choose your desired category"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v("There is no category available")]},proxy:true}],null,false,4106984509),model:{value:(_vm.product.productCategoryId),callback:function ($$v) {_vm.$set(_vm.product, "productCategoryId", $$v)},expression:"product.productCategoryId"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Price","label-for":"priceValue"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"priceValue","state":errors.length > 0 ? false:null},model:{value:(_vm.product.priceValue),callback:function ($$v) {_vm.$set(_vm.product, "priceValue", $$v)},expression:"product.priceValue"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Min Order","label-for":"minOrder"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minOrder","state":errors.length > 0 ? false:null},model:{value:(_vm.product.minOrder),callback:function ($$v) {_vm.$set(_vm.product, "minOrder", $$v)},expression:"product.minOrder"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Choose Product Shipments","label-for":"shipments"}},[(_vm.shipments)?_c('v-select',{attrs:{"id":"shipments","multiple":true,"options":_vm.shipments,"reduce":function (shipment) { return shipment.id; },"label":"name","placeholder":"Choose your desired shipments"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v("There is no shipments available")]},proxy:true}],null,false,2356231790),model:{value:(_vm.product.shipments),callback:function ($$v) {_vm.$set(_vm.product, "shipments", $$v)},expression:"product.shipments"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Short Description","label-for":"shortDescription"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"shortDescription","state":errors.length > 0 ? false:null},model:{value:(_vm.product.shortDescription),callback:function ($$v) {_vm.$set(_vm.product, "shortDescription", $$v)},expression:"product.shortDescription"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Full Description"}},[_c('ckEditorMain',{ref:"editorContent",attrs:{"defaultContent":_vm.product.fullDescription},on:{"getEditorContent":_vm.setEditorContent}})],1)],1)],1)],1),_c('b-card',[_c('b-row',[_c('h4',{staticClass:"text-primary col-12 mb-2"},[_vm._v("Properties")]),_vm._l((_vm.product.properties),function(property,idx){return _c('b-col',{key:idx,attrs:{"cols":"12"}},[_c('ProductCategoryProperty',{attrs:{"product":_vm.product,"property":property,"idx":idx,"CPG":_vm.CPG}})],1)}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"col-12 col-md-2",attrs:{"block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"variant":"success"},on:{"click":_vm.addProperty}},[_vm._v(" + Add property ")])],1)],2)],1),_c('b-card',[_c('b-row',[_c('h4',{staticClass:"text-primary col-12 mb-2"},[_vm._v("Trailer")]),_c('b-col',{attrs:{"cols":"12","md":"3"}},[(_vm.base64Trailer)?_c('video',{staticStyle:{"max-height":"30rem"},attrs:{"controls":"","crossorigin":"","playsinline":"","src":_vm.base64Trailer}}):_vm._e(),_c('b-button',{staticClass:"mt-2 col-6",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$refs.refInputEl2.click()}}},[_c('input',{ref:"refInputEl2",staticClass:"d-none",attrs:{"accept":".webm,.mp4,.ogg","type":"file"},on:{"change":_vm.setVideo}}),_c('span',{},[_vm._v("Add video")])])],1)],1)],1),_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"col-12 col-md-2",attrs:{"block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"variant":"primary"},on:{"click":_vm.addProduct}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }